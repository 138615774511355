<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS DOCUMENTOS EN LA CARPETA: {{(this.$route.params.nombre).toUpperCase()}}
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-file fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaDocumentos.length}}</span>
                            <br>
                            <span class="text-muted ">Documentos registrados </span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de la carpeta {{this.$route.params.nombre}}</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" :to="{name: 'Documentos comerciales master'}">
                                <i class="fas fa-arrow-left fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Volver
                            </b-button>
                        </b-col>
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoDocumento = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo documento
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Documentos</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaDocumentos" :fields="camposDocumentos" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(etiqueta)="param">

                                        <h5 v-if="param.item.etiqueta==1">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Muy importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==2">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==3">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Poco importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==4">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> No importante
                                            </b-badge>
                                        </h5>

                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalModificarDocumento(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button class=" mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'" @click="descargarDocumento(param)">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarDocumento(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>

                        </b-row>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalNuevoDocumento">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-plus-square fa-md"></i>
                                    <span class="h5"> Nuevo documento </span>
                                </h6>
                                <CButtonClose @click="modalNuevoDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(registrarDocumento)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="etiqueta" rules="required" v-slot="{errors}">
                                                <b-form-group label="Etiquetas:" class="mb-2">
                                                    <v-select :reduce="comboEtiqueta => comboEtiqueta.idEtiqueta" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.etiqueta , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.etiqueta" :options="comboEtiqueta" @search:blur="blurEtiqueta">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <!-- {{datosDocumento.archivoMeta}} -->
                                        <b-col md="12">
                                            <validation-provider :rules="{required: true}" name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-form-file ref="file1" v-model="file" :state="(getValidationState(validationContext) )" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleFileUpload($event,1)"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalNuevoDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalModificarDocumento">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-edit fa-md"></i>
                                    <span class="h5"> Actualizar documento </span>
                                </h6>
                                <CButtonClose @click="modalModificarDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(modificarDocumento)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="etiqueta" rules="required" v-slot="{errors}">
                                                <b-form-group label="Etiquetas:" class="mb-2">
                                                    <v-select :reduce="comboEtiqueta => comboEtiqueta.idEtiqueta " label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarDocumento.etiqueta , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarDocumento.etiqueta" :options="comboEtiqueta" @search:blur="blurEtiqueta">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <!-- <b-col md="12">
                                            <validation-provider name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-overlay :show="overlayDocumento" spinner-small rounded="sm" opacity="0.6">
                                                        <template #overlay>
                                                            <b-overlay :show="true" class="d-inline text-muted" spinner-small rounded="sm" opacity="0.6"></b-overlay>
                                                            <span class="px-3 text-muted"> Cargando ....</span>
                                                        </template>
                                                        <b-form-file v-model="datosModificarDocumento.archivoMeta" :state="(getValidationState(validationContext) && !overlayDocumento )" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf, application/msword, application/vnd.ms-excel" browse-text="Seleccionar" ref="input-documento" @change="subirDocumento"></b-form-file>
                                                    </b-overlay>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    <span :class="alertaArchivo.color" v-if="datosModificarDocumento.archivoMeta != null && !overlayDocumento" style="width: 100%;margin-top: 0.25rem;font-size: 80%">{{alertaArchivo.mensaje}}</span>
                                                </b-form-group>
                                            </validation-provider>
                                            <span v-if="datosModificarDocumento.url!=''">{{datosModificarDocumento.nombreArchivo}}</span>
                                        </b-col> -->
                                        <b-col md="12">
                                            <!-- {{datosModificarDocumento.name}} -->
                                            <!-- {{datosModificarDocumento.archivoMeta}} -->
                                            <validation-provider :rules="{}" name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-form-file ref="file2" v-model="updateFile" :state="(getValidationState(validationContext) )" :placeholder="datosModificarDocumento.nombreDocumento == '' ?  'Elija un archivo o arrástrelo aquí...' : datosModificarDocumento.nombreDocumento" accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleFileUpload($event,2)"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>

                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalModificarDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>

                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import firebase from 'firebase';
const dbDocumentos = firebase.firestore().collection('documentos')
const dbCarpeta = firebase.firestore().collection('carpetas');

export default {
    data() {
        return {
            loading: false,
            downloadUrl: '',

            modalNuevoDocumento: false,
            modalModificarDocumento: false,
            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],

            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboEtiqueta: [{
                    idEtiqueta: '1',
                    descripcion: 'Muy Importante',
                },
                {
                    idEtiqueta: '2',
                    descripcion: 'Importante',
                }, {
                    idEtiqueta: '3',
                    descripcion: 'Poco Importante',
                }, {
                    idEtiqueta: '4',
                    descripcion: 'No Importante',
                }
            ],
            camposDocumentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "fechaModificacion",
                    label: "Última modificación",
                    class: "text-center",
                },
                {
                    key: "etiqueta",
                    label: "Etiqueta",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDocumentos: [],
            datosDocumento: {
                idCarpeta: '',
                nombre: '',
                etiqueta: '',
                urlDocumento: null,
                nombreDocumento: null,
                estado: 2,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                // archivoMeta: null,
            },
            datosModificarDocumento: {
                idDocumento: '',
                idCarpeta: '',
                nombre: '',
                etiqueta: '',
                urlDocumento: null,
                nombreDocumento: null,
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                estado: 2,
            },
            disabled: false,
            file: null,
            updateFile: null
        }
    },
    methods: {
        handleFileUpload(e,i) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${i}`].reset();
                    return;
                }
            //    model = this.$refs[`file${i}`].files[0];
            }
        },
       
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarDocumentos() {
            let me = this;
            dbCarpeta
                .doc(me.datosDocumento.idCarpeta)
                .onSnapshot((doc) => {
                    if (doc.data().estado == 2) {
                        // doc.forEach((doc) => {
                        // console.log(doc.ref)
                        doc.ref.collection("documentos").where('estado', '==', 2)
                            .onSnapshot((doc) => {
                                me.listaDocumentos = [];
                                doc.forEach((doc) => {
                                    me.listaDocumentos.push({
                                        idDocumento: doc.id,
                                        estado: doc.data().estado,
                                        etiqueta: doc.data().etiqueta,
                                        fechaCreacion: moment(doc.data().fechaCreacion.toDate()).format('YYYY-MM-DD'),
                                        fechaModificacion: moment(doc.data().fechaModificacion.toDate()).format('YYYY-MM-DD'),
                                        idCarpeta: doc.data().idCarpeta,
                                        nombre: doc.data().nombre,
                                        nombreDocumento: doc.data().nombreDocumento,
                                        urlDocumento: doc.data().urlDocumento,
                                    })
                                });

                            });
                        // });
                    }
                });
        },
        blurEtiqueta() {
            this.computedForm.refs.etiqueta.validate();
        },
        async registrarDocumento() {
            let me = this;
            me.disabled = true;
            try {
                const file = me.file;
                console.log(file.name)
                if (file) {
                    const response = await firebase
                        .storage()
                        .ref(`admin/documentacion/${file.name}`)
                        .put(file);
                    const url = await response.ref.getDownloadURL();
                    me.datosDocumento.nombreDocumento = file.name;
                    me.datosDocumento.urlDocumento = url;
                }
            } catch (error) {
                me.file = null;
                me.datosDocumento.nombreDocumento = null;
                me.datosDocumento.urlDocumento = null;
                me.swat('warning', 'No se pudo subir el documento ')
            }
            dbCarpeta.doc(me.datosDocumento.idCarpeta)
                .collection('documentos').add(me.datosDocumento)
                .then((docRef) => {
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalNuevoDocumento = false
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
            me.disabled = false;

        },
        descargarDocumento(param) {
            window.open(param.item.urlDocumento)
        },
        async modificarDocumento() {
         let me = this;
            me.disabled = true;
            try {
                const file = me.updateFile;
                if (file) {
                    const response = await firebase
                        .storage()
                        .ref(`admin/documentacion/${file.name}`)
                        .put(file);
                    const url = await response.ref.getDownloadURL();
                    me.datosModificarDocumento.nombreDocumento = file.name;
                    me.datosModificarDocumento.urlDocumento = url;
                }
            } catch (error) {
                me.updateFile = null;
                me.swat('warning', 'No se pudo subir el documento ')
            }
            dbCarpeta
                .doc(me.datosModificarDocumento.idCarpeta)
                .collection('documentos')
                .doc(me.datosModificarDocumento.idDocumento)
                .update({
                    nombre: me.datosModificarDocumento.nombre,
                    etiqueta: me.datosModificarDocumento.etiqueta,
                    urlDocumento: me.datosModificarDocumento.urlDocumento,
                    nombreDocumento: me.datosModificarDocumento.nombreDocumento,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalModificarDocumento = false
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
            me.disabled = false;
        },
        abrirModalModificarDocumento(param) {
            let me = this;
            me.datosModificarDocumento.nombre = param.item.nombre;
            me.datosModificarDocumento.etiqueta = param.item.etiqueta;
            me.datosModificarDocumento.urlDocumento = param.item.urlDocumento;
            me.datosModificarDocumento.idDocumento = param.item.idDocumento;
            me.datosModificarDocumento.nombreDocumento = param.item.nombreDocumento;
            me.modalModificarDocumento = true;
        },
        resetModalNuevoDocumento() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosDocumento.nombre = '',
                this.datosDocumento.urlDocumento = null,
                this.datosDocumento.nombreDocumento = null,
                this.datosDocumento.archivoMeta = null,
                this.datosDocumento.etiqueta = null,
                this.overlayDocumento = false
        },
        resetModalModificarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosModificarDocumento.nombre = '',
                this.datosModificarDocumento.url = null,
                this.datosModificarDocumento.archivoMeta = null,
                this.datosModificarDocumento.etiqueta = null,
                this.overlayDocumento = false
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCarpeta.doc(this.datosDocumento.idCarpeta)
                        .onSnapshot((doc) => {
                            // doc.forEach((doc) => {
                            doc.ref.collection("documentos")
                                .doc(param.item.idDocumento).update({
                                    estado: 1,
                                })
                                .then(() => {
                                    me.swat('success', 'Eliminado satisfactoriamente')
                                })
                                .catch((error) => {
                                    me.swat('error', 'Algo salió mal!')
                                });
                            // });
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalNuevoDocumento: function (val) {
            if (val == false) {
                this.resetModalNuevoDocumento();
            }
        },
        modalModificarDocumento: function (val) {
            if (val == false) {
                this.resetModalModificarDocumento();
            }
        }
    },
    mounted() {
        this.datosDocumento.idCarpeta = this.datosModificarDocumento.idCarpeta = this.$route.params.id;
        this.listarDocumentos();
    }
}
</script>
